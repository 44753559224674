import React from "react";
import firstPageImage from '../assets/print-first-page.png';
import lastPageImage from '../assets/print-last-page.png';

const PrintOnly = props => (
    <section class="page-section portfolio show-on-print">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 mb-12 mb-lg-12">
                    <img id="firstPageImage" class="hidden" src={firstPageImage} alt="" />
                    <img id="lastPageImage" class="hidden" src={lastPageImage} alt="" />
                </div>
            </div>
        </div>
    </section>
)

export default PrintOnly;