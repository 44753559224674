import React from "react";
import calculatorIcon from '../assets/04_Neocrete_Calculator_Illustrations_Mobile_380x227px-04.png'

import greenStarPoints0 from '../assets/greenStarPoints-0.png'
import greenStarPoints1 from '../assets/greenStarPoints-1.png'
import greenStarPoints2 from '../assets/greenStarPoints-2.png'

const greenStartPointsIcon = [greenStarPoints0, greenStarPoints1, greenStarPoints2];

const Header = props => (
    <header id="header" className={'masthead bg-primary-light text-left green-star-points-' + props.greenStarPoints}>
        <div class="container d-flex align-items-left flex-column">
            <div class="row justify-content-center">
                {props.resultMode ?
                    <div class="col-md-6 col-lg-6 mb-6">
                        <h1 class="masthead-heading mb-0">Calculation Results</h1>
                        <br />
                        <p class="masthead-subheading font-weight-light mb-0">
                            Please see below for the estimations of the environmental impacts of Actual concrete compared with Reference concrete and the relevant Green Star points that can be claimed for concrete on the project.                
                        </p>
                    </div>
                    :
                    <div class="col-md-6 col-lg-6 mb-6">
                        <h1 class="masthead-heading mb-0">Neocrete</h1>
                        <h1 class="masthead-heading mb-0">eCalculator</h1>
                        <br />
                        <p class="masthead-subheading font-weight-light mb-0">
                            Use the eCalculator to estimate and compare the carbon footprint and other environmental impacts of concrete, depending on its mix design. Check how many Green Star points can be claimed for concrete on your project.
                        </p>
                    </div>
                }

                <div class="col-md-6 col-lg-6 mb-6">
                    <div class="portfolio-item mx-auto" data-toggle="modal" data-target="#portfolioModal2">
                        { !props.resultMode ?
                            <img class="img-fluid" src={calculatorIcon} alt="" />
                            :
                            <img class="img-fluid" src={greenStartPointsIcon[props.greenStarPoints]} alt="" />
                        }  
                    </div>
                </div>
            </div>
        </div>
    </header>
)

export default Header;