import React from "react";
import { ReactComponent as MixButton1 } from '../assets/Mix_Buttons-01.svg';
import { ReactComponent as MixButton2 } from '../assets/Mix_Buttons-02.svg';
import { ReactComponent as MixButton3 } from '../assets/Mix_Buttons-03.svg';
import { ReactComponent as MixButton4 } from '../assets/Mix_Buttons-04.svg';
import { ReactComponent as MixButton5 } from '../assets/Mix_Buttons-05.svg';
import { ReactComponent as MixButton6 } from '../assets/Mix_Buttons-06.svg';
import { ReactComponent as MixButton7 } from '../assets/Mix_Buttons-07.svg';
import { ReactComponent as MixButton8 } from '../assets/Mix_Buttons-08.svg';
import { ReactComponent as MixButton9 } from '../assets/Mix_Buttons-09.svg';
import { ReactComponent as MixButton10 } from '../assets/Mix_Buttons-10.svg';
export default class ResultTable extends React.Component {
    constructor() {
        super();
    }

    goToMix(index){
        var url = window.location.origin + window.location.pathname;    
        if (url.indexOf('?') > -1){
            url += `&mix=${index}`;
        }else{
            url += `?mix=${index}`;
        }
        window.location.href = url;
    }

    getButton(index){
        if(index === 0)
            return <MixButton1 class="mix-link-button hide-on-print" onClick={() => this.goToMix(index)}/>    
        if(index === 1)
            return <MixButton2 class="mix-link-button hide-on-print" onClick={() => this.goToMix(index)}/>    
        if(index === 2)
            return <MixButton3 class="mix-link-button hide-on-print" onClick={() => this.goToMix(index)}/>    
        if(index === 3)
            return <MixButton4 class="mix-link-button hide-on-print" onClick={() => this.goToMix(index)}/>    
        if(index === 4)
            return <MixButton5 class="mix-link-button hide-on-print" onClick={() => this.goToMix(index)}/>    
        if(index === 5)
            return <MixButton6 class="mix-link-button hide-on-print" onClick={() => this.goToMix(index)}/>    
        if(index === 6)
            return <MixButton7 class="mix-link-button hide-on-print" onClick={() => this.goToMix(index)}/>    
        if(index === 7)
            return <MixButton8 class="mix-link-button hide-on-print" onClick={() => this.goToMix(index)}/>    
        if(index === 8)
            return <MixButton9 class="mix-link-button hide-on-print" onClick={() => this.goToMix(index)}/>    
        if(index === 9)
            return <MixButton10 class="mix-link-button hide-on-print" onClick={() => this.goToMix(index)}/>    
    }

    render() {
        return <section id={this.props.id} className="page-section hidden-on-small-devices">
                <div class="container">
                    <div class="row row-margin">
                        <h3 class="text-left text-secondary">{this.props.params.label}</h3>

                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    {this.props.params.columns.map((column, i) =>
                                        <th>{column}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.params.rows.map((row, index) =>
                                    <tr>
                                        {row.map((value, innerIndex) =>
                                            <React.Fragment>
                                                
                                                { innerIndex === 2 ? 
                                                    <td class="text-left">
                                                        { this.getButton(index) }
                                                        <span class="show-on-print">{value}</span>
                                                    </td> 
                                                :
                                                    <td>{value}</td>
                                                }
                                            </React.Fragment>
                                        )}
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    
                    {this.props.params.footer.map((row, index) =>
                        <div class="row row-bordered row-margin-small">
                            {row.map((value, innerIndex) =>
                                <div className={innerIndex > 0 ? `col-md-3 col-lg-3 mb-3 ${this.props.id}-${innerIndex}` : "col-md-6 col-lg-6 mb-6"}>
                                    {value}
                                </div>
                            )}
                        </div>
                    )}
                </div>
        </section>
    }
}