import React from "react";

export default class InputText extends React.Component {

    eventHandler = event => {
        this.props.handler({target: this.props.params.target, value: event.target.value });
    };

    render() {
        return <div class="row row-bordered justify-content-center select-wrapper">
            <div class="col-md-6 col-lg-6 mb-6">
                <label class="mdb-main-label">{this.props.params.label}</label>
            </div>

            <div class="col-md-6 col-lg-6 mb-6">
                <input type="text" className="input-no-borders" value={this.props.value} placeholder="Enter" onChange={this.eventHandler}/>
            </div>
        </div>
    }
}