import React from "react";
import { ReactComponent as Logo } from '../assets/NEOCRETE_LOGO_RGB_PRIORITY_1.svg';
import logo from '../assets/NEOCRETE_LOGO_V2.webp';

import calculateButton from '../assets/eCalculate_Button.png';

const Navigation = props => (
    <nav class="navbar navbar-expand-lg bg-secondary fixed-top" id="mainNav">
        <div class="container">
            <a class="" href="https://www.neocrete.co.nz/">
                <img class="main-nav-logo" src={logo} alt="" />
                {/* <Logo className="logo-nav"/> */}
            </a>
            <button class="navbar-toggler navbar-toggler-right font-weight-bold bg-primary text-white rounded" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                Menu
                  <i class="fas fa-bars"></i>
            </button>
            <div class="collapse navbar-collapse" id="navbarResponsive">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item mx-0 mx-lg-1"><a href="https://www.neocrete.co.nz/ourstory" class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger">Our Story</a></li>
                    <li class="nav-item mx-0 mx-lg-1"><a href="https://www.neocrete.co.nz/ourproduct" class="nav-link our-product py-3 px-0 px-lg-3 rounded js-scroll-trigger">Our Product</a></li>
                    <li class="nav-item mx-0 mx-lg-1"><a href="https://www.neocrete.co.nz/technical" class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger">Technical</a></li>
                    <li class="nav-item mx-0 mx-lg-1"><a href="https://www.neocrete.co.nz/contact" class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger">Contact</a></li>
                    <li class="nav-item mx-0 mx-lg-1"><a href="https://d5-green-calculator.neocrete.co.nz" class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger">eCalculator</a></li>
                    {/* <li class="nav-item mx-0 mx-lg-1">
                        <a class="nav-link rounded js-scroll-trigger">
                          <img src={calculateButton} alt="" />
                        </a>
                    </li> */}
                </ul>
            </div>
        </div>
    </nav>
)

export default Navigation;