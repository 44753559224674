import React from "react";
import Select from 'react-select'

export default class SelectItem extends React.Component {
    render() {
        return <div class="row row-bordered justify-content-center select-wrapper">
            <div class="col-md-6 col-lg-6 mb-6">
                <label class="mdb-main-label">{this.props.params.label}</label>
            </div>

            <div class="col-md-6 col-lg-6 mb-6">
                <Select className="select-no-borders" value={this.props.value} options={this.props.params.options} onChange={this.props.handler} />
            </div>
        </div>
    }
}