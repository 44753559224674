
const carbonEmissions = {
  purposeCement: { NZ: { factor: 0.732 }, AUS: { factor: 0.94595064 } },
  d5Green: { factor: 0.80465873 },
  admixtures: { factor: 1.9217898 },
  silicaFume: { factor: 0.0030566665 },
  flyAsh: { factor: 0.020199139 },
  sand: { factor: 0.0045404332 },
  coarseAggregate: { factor: 0.011584534 },
  water: { factor: 0.0005457468 },
  sandTransport: { factor: 0.00017969326 },
  coarseAggregateTransport: { factor: 0.00017969326 },
  electricity: { NZ: { factor: 0.1535813064 }, AUS: { factor: 0.888401772 } },
  diesel: { factor: 2.9386772 },
};

const lcaFactors = {
  purposeCement: {
    NZ: {
      globalWarmingPotential: 0.732,
      ozoneLayerDepletion: 5.29E-15,
      acidification: 0.000823,
      eutrophication: 0.000207,
      photochemicalOxidation: 0.0000683,
      abioticElements: 0.00000000999,
      abioticFossilFuels: 2.86 },
    AUS: {
      globalWarmingPotential: 0.94595064,
      ozoneLayerDepletion: 0.0000000071868082,
      acidification: 0.0023686363,
      eutrophication: 0.00044550305,
      photochemicalOxidation: 0.000062343349,
      abioticElements: 0.0000002862998,
      abioticFossilFuels: 5.4395058
     }
  },
  d5Green: {
    globalWarmingPotential: 0.80465873,
    ozoneLayerDepletion: 0.00000011631177,
    acidification: 0.0051388991,
    eutrophication: 0.0013613598,
    photochemicalOxidation: 0.00032520024,
    abioticElements: 0.0000035062673,
    abioticFossilFuels: 15.304156
  },
  admixtures: {
    globalWarmingPotential: 1.9217898,
    ozoneLayerDepletion: 0.0000000056297919,
    acidification: 0.0030482531,
    eutrophication: 0.0010574737,
    photochemicalOxidation: 0.00031583279,
    abioticElements: 0.0000012331367,
    abioticFossilFuels: 29.746221 
  },
  silicaFume: {
    globalWarmingPotential: 0.0030566665,
    ozoneLayerDepletion: 0.00000000053233952,
    acidification: 0.000014593609,
    eutrophication: 0.0000037670711,
    photochemicalOxidation: 0.00000057281771,
    abioticElements: 0.0000000080435539,
    abioticFossilFuels: 0.046497435
  },
  flyAsh: {
    globalWarmingPotential: 0.020199139,
    ozoneLayerDepletion: 0.00000000261518,
    acidification: 0.000061845496,
    eutrophication: 0.000013304093,
    photochemicalOxidation: 0.0000042054115,
    abioticElements: 0.000000066514685,
    abioticFossilFuels: 0.30346552
  },
  sand: {
    globalWarmingPotential: 0.0045404332,
    ozoneLayerDepletion: 0.00000000021879155,
    acidification: 0.0000092326744,
    eutrophication: 0.0000025122271,
    photochemicalOxidation: 0.00000044071991,
    abioticElements: 0.0000000097458534,
    abioticFossilFuels: 0.055029976
  },
  coarseAggregate: {
    globalWarmingPotential: 0.011584534,
    ozoneLayerDepletion: 0.00000000029839114,
    acidification: 0.000024115296,
    eutrophication: 0.0000070116919,
    photochemicalOxidation: 0.0000010026845,
    abioticElements: 0.000000028343495,
    abioticFossilFuels: 0.13463736
  },
  water: {
    globalWarmingPotential: 0.0005457468,
    ozoneLayerDepletion: 0.00000000011424004,
    acidification: 0.0000026812209,
    eutrophication: 0.0000012298398,
    photochemicalOxidation: 0.0000001488078,
    abioticElements: 0.0000000014478535,
    abioticFossilFuels: 0.0081023567
  },
  sandTransport: {
    globalWarmingPotential: 0.00017969326,
    ozoneLayerDepletion: 2.1786239E-11,
    acidification: 0.00000075944195,
    eutrophication: 0.00000017351939,
    photochemicalOxidation: 0.000000050924963,
    abioticElements: 0.00000000065618979,
    abioticFossilFuels: 0.0026128556
  },
  coarseAggregateTransport: {
    globalWarmingPotential: 0.00017969326,
    ozoneLayerDepletion: 2.1786239E-11,
    acidification: 0.00000075944195,
    eutrophication: 0.00000017351939,
    photochemicalOxidation: 0.000000050924963,
    abioticElements: 0.00000000065618979,
    abioticFossilFuels: 0.0026128556
  },
  electricity: {
    NZ: {
      globalWarmingPotential: 0.1535813064,
      ozoneLayerDepletion: 0.00000000490100364,
      acidification: 0.000597361644,
      eutrophication: 0.000455744232,
      photochemicalOxidation: 0.00002571893028,
      abioticElements: 0.000000523938744,
      abioticFossilFuels: 2.371250628
    },
    AUS: {
      globalWarmingPotential: 0.888401772,
      ozoneLayerDepletion: 0.000000001452845448,
      acidification: 0.00133347546,
      eutrophication: 0.000456023484,
      photochemicalOxidation: 0.00002704287312,
      abioticElements: 0.000001081995912,
      abioticFossilFuels: 9.98675244
    }
  },
  diesel: {
    globalWarmingPotential: 2.9386772,
    ozoneLayerDepletion: 0.00000000034938873,
    acidification : 0.018640278,
    eutrophication: 0.0038718753,
    photochemicalOxidation: 0.00066090782,
    abioticElements: 0.000000067280824,
    abioticFossilFuels: 44.55739
  },
};

const gradeCement = {
  20: 260,
  25: 290,
  30: 320,
  35: 360,
  40: 410,
  50: 470,
  65: 500,
  80: 530
}

export default class CalculatorUtils {

  static calculateCarbonEmissions(target, country, value) {
    if (!value) return 0;

    value = eval(value);

    if (carbonEmissions[target][country]) {
      return carbonEmissions[target][country].factor * value;
    }

    return carbonEmissions[target].factor * value;
  }


  static getPurposeCementReference(grade) {
    return gradeCement[grade];
  }

  static calculateLCA(target, country, property, value) {
    if (!value) return 0;
    
    value = eval(value);

    if (lcaFactors[target][country]) {
      return lcaFactors[target][country][property] * value;
    }

    return lcaFactors[target][property] * value;
  }
}
