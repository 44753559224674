import React from "react";
import { Bar, Chart } from 'react-chartjs-2';
import ResultTable from "./resultTable";
import Button from 'react-bootstrap/Button';
import downloadReportIcon from '../assets/Download_Full_Report_Button.png';
import continueIcon from '../assets/Continue_Button.png';
import { ReactComponent as UpIcon } from '../assets/Up_Button.svg';
import Utils from "./utils";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import keyGreenStar2Icon from '../assets/Key_2_Green_Star.png';
import keyGreenStar1Icon from '../assets/Key_1_Green_Star.png';


const COMMON_COLUMNS = ["Concrete", "Type", "Mix Label", "MPA (Following AS1379)", "Volume (m3)"];
export default class CalculationResult extends React.Component {
    constructor() {
        super();

        this.state = {
            showDownloadForm: false,
            showConfirmMessage: false,
            waterMix: 0,
            waterRef: 0,
            embodiedCO2Mix: 0,
            embodiedCO2Ref: 0,
            portlandCementRef: 0,
            portlandCementMix: 0
        }
    }

    writeLineInGraph(chart, lineAt, color){
        let ctxPlugin = chart.chart.ctx;
        let xAxe = chart.scales[chart.config.options.scales.xAxes[0].id];
        let yAxe = chart.scales[chart.config.options.scales.yAxes[0].id];
           
        if(yAxe.min != 0) return;
        
        ctxPlugin.strokeStyle = color;
        ctxPlugin.lineWidth = 3;

        ctxPlugin.beginPath();
        lineAt = (lineAt - yAxe.min) * (100 / yAxe.max);
        lineAt = (100 - lineAt) / 100 * (yAxe.height) + yAxe.top;

        ctxPlugin.moveTo(xAxe.left, lineAt);
        
        //writing reference lines over the first two columns
        ctxPlugin.lineTo(xAxe.right / 2.75, lineAt);

        ctxPlugin.stroke();
    }

    componentDidMount(){
        const writeLineInGraph = this.writeLineInGraph.bind(this);

        Chart.pluginService.register({
            afterDraw: function (chart, easing) {
                if (typeof chart.config.options.greenStart1 != 'undefined') {
                    let lineAt = chart.config.options.greenStart1;
                    writeLineInGraph(chart, lineAt, "#D3D3D3");
                } 

                if (typeof chart.config.options.greenStart2 != 'undefined') {
                    let lineAt = chart.config.options.greenStart2;
                    writeLineInGraph(chart, lineAt, "black");
                } 
            }
          });
    }

    getGraphData() {
        const { portlandCementRef, portlandCementMix, embodiedCO2Ref, embodiedCO2Mix, waterRef, waterMix } = this.state;

        return {
            labels: [
                ["Reference Mix:", " - ", "Portland Cement"],
                ["Actual Mix:", " - ", "Portland Cement", `${this.calculateReduction(portlandCementMix, portlandCementRef)}% Reduction`],
                ["Reference Mix:", " - ", "eCO2"],
                ["Actual Mix:", " - ", "eCO2", `${this.calculateReduction(embodiedCO2Mix, embodiedCO2Ref)}% Reduction`],
                ["Reference Mix:", " - ", "Water"],
                ["Actual Mix:", " - ", "Water", `${this.calculateReduction(waterMix, waterRef)}% Reduction`],
            ],
            datasets: [
                {
                    data: [portlandCementRef, portlandCementMix, embodiedCO2Ref, embodiedCO2Mix, waterRef, waterMix],
                    backgroundColor: [
                        '#BED7E9',
                        '#BED7E9',
                        '#F0CFD7',
                        '#F0CFD7',
                        '#407EDA',
                        '#407EDA',
                    ],
                    borderColor: [
                        '#BED7E9',
                        '#BED7E9',
                        '#F0CFD7',
                        '#F0CFD7',
                        '#407EDA',
                        '#407EDA',
                    ],
                    borderWidth: 1,
                },
            ],
        }
    }

    goToTop() {
        window.scrollTo(0, document.body.scrollHeight / 6);
    }

    submitForm(e) {
        e.preventDefault();
        this.setState({ showConfirmMessage: true })
    }

    getGlobalState() {
        return Utils.getGlobalState();
    }

    getReferenceState() {
        return Utils.getReferenceState();
    }

    getPortlandCementData() {
        let { mixes, concreteType, referenceState, referenceTotal, actualTotal, rows } = this.initResultFields();

        for (const mix of mixes) {
            const concreteVolume = eval(mix.selectedConcreteVolume);

            const fields = [...this.getCommonFields(mix, concreteType, concreteVolume)];

            fields.push(referenceState[mix.index].purposeCement);

            referenceTotal += Utils.roundNumber(concreteVolume * referenceState[mix.index].purposeCement);
            fields.push(Utils.roundNumber(concreteVolume * referenceState[mix.index].purposeCement));

            fields.push(mix.purposeCement);
            fields.push(Utils.roundNumber(concreteVolume * mix.purposeCement));
            actualTotal += Utils.roundNumber(concreteVolume * mix.purposeCement);

            rows.push(fields);
        }

        const cementReduction = this.calculateReduction(actualTotal, referenceTotal);
        const greenStarPoints = this.getGreenStarPoints(concreteType, cementReduction);

        this.props.calculationHandler({ greenStarPoints: greenStarPoints });

        if(this.state.portlandCementRef !== referenceTotal && this.state.portlandCementMix !== actualTotal)
            this.setState({ portlandCementRef : referenceTotal, portlandCementMix: actualTotal });

        return {
            label: "Portland Cement",
            columns: [...COMMON_COLUMNS, "Reference Case Portland Cement Content (kg/m3)", "Total Portland Cement Content Under the Reference Case", "Actual Portland Cement Content", "Actual Total Portland Cement Content"],
            rows: rows,
            footer: this.getPortlandCementFooter(Utils.roundNumber(referenceTotal), Utils.roundNumber(actualTotal), cementReduction, greenStarPoints)
        }
    }

    getPortlandCementFooter(referenceTotal, actualTotal, cementReduction, greenStarPoints){
        const footer = [];
        
        footer.push(["Overall Total Portland Cement (kg)", referenceTotal, actualTotal]);
        footer.push(["Overall Perecentage of Reduction (%)", "", `${cementReduction}%`]);

        if(this.getGlobalState().selectedCountry.value === "NZ")
            footer.push(["Green Star Points Claimed", "", greenStarPoints]);

        return footer;
    }

    getGreenStart2Line(){
        let { concreteType } = this.initResultFields();

        const ref = this.state.portlandCementRef;

        if (concreteType.toLowerCase() === "precast") {
            return ref * (1 - 0.15);
        }
        return ref * (1 - 0.25);
    }

    getGreenStart1Line(){
        let { concreteType } = this.initResultFields();

        const ref = this.state.portlandCementRef;

        if (concreteType.toLowerCase() === "precast") {
            return ref * (1 - 0.10);
        }
        return ref * (1 - 0.15);
    }

    getGreenStarPoints(concreteType, cementReduction) {
        cementReduction = Math.abs(cementReduction);

        if (concreteType.toLowerCase() === "precast") {
            if (cementReduction >= 15) {
                return 2;
            } else if (cementReduction >= 10) {
                return 1;
            }
        } else {
            if (cementReduction >= 25) {
                return 2;
            } else if (cementReduction >= 15) {
                return 1;
            }
        }

        return 0;
    }

    getCommonFields(mix, concreteType, concreteVolume) {
        const fields = [];

        fields.push(mix.selectedMixName);
        fields.push(concreteType);
        fields.push(`Mix ${mix.index + 1}`);
        fields.push(mix.selectedConcreteGrade.value);
        fields.push(concreteVolume);

        return fields;
    }

    calculateReduction(actual, reference) {
        return Math.round((actual / reference - 1) * 100);
    }

    getEmbodiedCO2Data() {
        let { mixes, concreteType, referenceState, referenceTotal, actualTotal, rows } = this.initResultFields();

        for (const mix of mixes) {
            const concreteVolume = eval(mix.selectedConcreteVolume);
            const fields = [...this.getCommonFields(mix, concreteType, concreteVolume)];

            fields.push(referenceState[mix.index].globalWarmingPotential);
            fields.push(Utils.roundNumber(referenceState[mix.index].globalWarmingPotential * concreteVolume));
            referenceTotal += Utils.roundNumber(referenceState[mix.index].globalWarmingPotential * concreteVolume);

            fields.push(mix.globalWarmingPotential);
            fields.push(Utils.roundNumber(mix.globalWarmingPotential * concreteVolume));
            actualTotal += Utils.roundNumber(mix.globalWarmingPotential * concreteVolume)

            rows.push(fields);
        }

        if(this.state.embodiedCO2Ref !== referenceTotal && this.state.embodiedCO2Mix !== actualTotal)
            this.setState({ embodiedCO2Ref : referenceTotal, embodiedCO2Mix: actualTotal });

        return {
            label: "Embodied CO2",
            columns: [...COMMON_COLUMNS, "Reference Case eCO2 (kg/m3)", "Total eCO2 Content Under the Reference Case", "Actual Embodied CO2 Content (kg/m3)", "Actual Total Embodied CO2 Content"],
            rows: rows,
            footer: [
                ["Overall Total Embodied CO2 (m3)", Utils.roundNumber(referenceTotal), Utils.roundNumber(actualTotal)],
                ["Overall Perecentage of Reduction (%)", "", `${this.calculateReduction(actualTotal, referenceTotal)}%`],
            ]
        }
    }

    getWaterData() {
        let { mixes, concreteType, referenceState, referenceTotal, actualTotal, rows } = this.initResultFields();

        for (const mix of mixes) {
            const concreteVolume = eval(mix.selectedConcreteVolume);
            const fields = [...this.getCommonFields(mix, concreteType, concreteVolume)];

            fields.push(referenceState[mix.index].water);
            fields.push(Utils.roundNumber(referenceState[mix.index].water * concreteVolume));
            referenceTotal += Utils.roundNumber(referenceState[mix.index].water * concreteVolume);

            fields.push(mix.water);
            fields.push(Utils.roundNumber(mix.water * concreteVolume));

            actualTotal += Utils.roundNumber(mix.water * concreteVolume);

            rows.push(fields);
        }

        if(this.state.waterRef !== referenceTotal && this.state.waterMix !== actualTotal)
            this.setState({ waterRef : referenceTotal, waterMix: actualTotal });

        return {
            label: "Water",
            columns: [...COMMON_COLUMNS, "Reference Case Water Content (kg/m3)", "Total Water Content Under the Reference Case", "Actual Water Content (kg/m3)", "Actual Total Water Content"],
            rows: rows,
            footer: [
                ["Overall Total Water (m3)", Utils.roundNumber(referenceTotal), Utils.roundNumber(actualTotal)],
                ["Overall Perecentage of Reduction (%)", "", `${this.calculateReduction(actualTotal, referenceTotal)}%`],
            ]
        }
    }

    initResultFields() {
        const globalState = this.getGlobalState();
        const referenceState = this.getReferenceState();
        const mixes = globalState.mixes;
        const concreteType = globalState.selectedConcreteType.label;

        const rows = [];
        let actualTotal = 0;
        let referenceTotal = 0;

        return { mixes, concreteType, referenceState, referenceTotal, actualTotal, rows };
    }

    async printPDF() {
        this.props.showSpinner(true, 'Your PDF is being generated. Please wait...');
        this.props.printHandler(true);
        window.scrollTo(0, 0);

        try {
            //it needs  to run in another thread to make sure that the changes are already reflected in the page before it starts
            setTimeout(() => {
                this.generatePdf();
            }, 1);
        } catch (e) {
            alert('Something went wrong. Please contact the admin.');
            this.props.printHandler(false);
        }
    }

    async generatePdf() {
        //fix print on mobile
        const viewportMeta = document.getElementById("viewportMeta").getAttribute("content");
        document.getElementById("viewportMeta").setAttribute("content", "width=1500");
        
        const isMobile = Utils.isSmallDevice();

        if(isMobile){
            document.getElementsByClassName("_loading_overlay_content")[0].style.left = "-35rem";
        }

        const firstPageImage = this.getImageAsBase64('firstPageImage');
        const requestDetails = await this.getImageData('request-details');

        const outputGraph = await this.getImageData('cement-output-graph');
        const outputPortlandCement = await this.getImageData('output-portland-cement');
        const outputEmbodiedCo2 = await this.getImageData('output-embodied-co2');
        const outputWater = await this.getImageData('output-water');
        const lastPageImage = this.getImageAsBase64('lastPageImage');
        const DEFAULT_MARGIN_TOP = 10;
        const DEFAULT_MARGIN_LEFT = 20;

        const pdf = new jsPDF('p', 'px', 'a4', true);

        this.addImage(pdf, firstPageImage);
        pdf.addPage();

        if(isMobile){
            //there is a bug which shows a blank page only happening in a real mobile devide
            //this negative y is to fix that. 
            this.addImage(pdf, requestDetails, { y: -180, zoom: 1.2 });
        }else{
            this.addImage(pdf, requestDetails, { y: DEFAULT_MARGIN_TOP, zoom: 1.2 });
        }

        pdf.addPage();
        this.addImage(pdf, outputGraph, { y: DEFAULT_MARGIN_TOP, zoom: 1.3 });

        pdf.addPage();
        this.addImage(pdf, outputPortlandCement, { zoom: 1.2 });

        pdf.addPage();
        this.addImage(pdf, outputEmbodiedCo2, { zoom: 1.2 });

        pdf.addPage();
        this.addImage(pdf, outputWater, { zoom: 1.2 });

        await this.addMixPages(pdf, DEFAULT_MARGIN_LEFT, DEFAULT_MARGIN_TOP);

        pdf.addPage();
        this.addImage(pdf, lastPageImage);

        pdf.save(`report.pdf`);
        document.getElementById("viewportMeta").setAttribute("content", viewportMeta);

        this.props.printHandler(false);
        this.props.showSpinner(false);
    }

    async addMixPages(pdf, DEFAULT_MARGIN_LEFT, DEFAULT_MARGIN_TOP) {
        const mixQuantity = this.getGlobalState().selectedMixQuantity.value;
        const isMobile = Utils.isSmallDevice();

        for (let index = 1; index <= mixQuantity; index++) {
            const mixHeader = await this.getImageData(`mix${index}-header`);
            const mixDetails = await this.getImageData(`mix${index}-details`);
            const mixGraph = await this.getImageData(`mix${index}-graph`);
            const mixLca = await this.getImageData(`mix${index}-lca`);

            const canvas = await html2canvas(document.getElementById(`mix${index}-header`));
            pdf.addPage();
            this.addImage(pdf, mixHeader);

            let canvasHeightDivider = isMobile ? 6 : 2.5;

            this.addImage(pdf, mixDetails, { x: 0, y: canvas.height / canvasHeightDivider });

            pdf.addPage();
            this.addImage(pdf, mixGraph);

            pdf.addPage();
            this.addImage(pdf, mixLca, { x: DEFAULT_MARGIN_LEFT, y: DEFAULT_MARGIN_TOP });
        }
    }

    getImageAsBase64(id) {
        const c = document.createElement('canvas');
        const img = document.getElementById(id);
        c.height = img.naturalHeight;
        c.width = img.naturalWidth;
        const ctx = c.getContext('2d');

        ctx.drawImage(img, 0, 0, c.width, c.height);
        return c.toDataURL();
    }

    async getImageData(id) {
        const canvas = await html2canvas(document.getElementById(id));

        // download output images for verification

        // var a = document.createElement('a');
        // a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
        // a.download = id + '.jpg';
        // a.click();

        return canvas.toDataURL('image/png');
    }

    addImage(pdf, imgData, custom) {
        const imgProps = pdf.getImageProperties(imgData);

        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        if (custom) {
            const customWidth = custom.width ? custom.width : pdfWidth;
            const customHeight = custom.height ? custom.height : pdfHeight;
            const x = custom.x ? custom.x : 0;
            const y = custom.y ? custom.y : 0;

            if (custom.zoom) {
                pdf.addImage(imgData, 'PNG', x, y, customWidth, customHeight * custom.zoom);
            } else {
                pdf.addImage(imgData, 'PNG', x, y, customWidth, customHeight);
            }
        } else {
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        }
    }

    render() {
        const isSmallDevice = Utils.isSmallDevice();

        return <React.Fragment>
            <section id="cement-output-graph" class="page-section portfolio">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-12 col-lg-12 mb-12">
                            <h3 class="text-left text-secondary mb-0">Cement, Water and C02 reduction achieved with D5 Green</h3>
                            <br />
                            { isSmallDevice ? 
                                <div class="cement-canvas-container">
                                    <Bar
                                        data={this.getGraphData()}
                                        options={{
                                            maintainAspectRatio: false,
                                            greenStart2: this.getGreenStart2Line(),
                                            greenStart1: this.getGreenStart1Line(),
                                            scales: {
                                                xAxes: [{
                                                    stacked: true,
                                                    ticks: { padding: 0 }
                                                }],
                                                yAxes: [{
                                                    stacked: true,
                                                    ticks: {
                                                        beginAtZero: true,
                                                        min: 0
                                                    }
                                                }]
                                            },
                                            title: {
                                                display: true,
                                                text: 'The calculation summary is represented by the total project content of cement, water and CO2.',
                                                fontSize: 15,
                                                fontColor: "#000"
                                            },
                                            legend: {
                                                display: false,
                                            }
                                        }}
                                    />
                                </div>
                            :
                                <Bar
                                    data={this.getGraphData()}
                                    options={{
                                        greenStart2: this.getGreenStart2Line(),
                                        greenStart1: this.getGreenStart1Line(),
                                        scales: {
                                            xAxes: [{
                                                stacked: true,
                                                ticks: { padding: 0 }
                                            }],
                                            yAxes: [{
                                                stacked: true,
                                                ticks: {
                                                    beginAtZero: true,
                                                    min: 0
                                                }
                                            }]
                                        },
                                        title: {
                                            display: true,
                                            text: 'The calculation summary is represented by the total project content of cement, water and CO2.',
                                            fontSize: 25,
                                            fontColor: "#000"
                                        },
                                        legend: {
                                            display: false,
                                        }
                                    }}
                                />
                            }
                        </div>
                    </div>
                    <div class="row no-padding no-margin justify-content-center">
                        <div class="col-md-12 col-lg-12 mb-12">
                            <img src={keyGreenStar1Icon} className="img-button key-green-star-icon" />
                            <span class="key-green-star-icon-text">
                                Cement content to achieve 1 Green Star point
                            </span>
                        </div>
                    </div>
                    <div class="row justify-content-center no-padding no-margin">
                        <div class="col-md-12 col-lg-12 mb-12">
                            <img src={keyGreenStar2Icon} className="img-button key-green-star-icon" />
                            <span class="key-green-star-icon-text">
                                Cement content to achieve 2 Green Star points
                            </span>
                        </div>
                    </div>
                    <div class="row no-padding no-margin">
                      <div class="col-md-12 col-lg-12 mb-12">
                            <span class="key-green-star-icon-text cement-guidelines">
                             General purpose cement for the Reference Mix is as per NZGBC guidelines
                            </span>
                        </div>
                    </div>
                </div>
            </section>
            <ResultTable id="output-portland-cement" params={this.getPortlandCementData()} printMode={this.props.printMode} />
            <ResultTable id="output-embodied-co2" params={this.getEmbodiedCO2Data()} printMode={this.props.printMode} />
            <ResultTable id="output-water" params={this.getWaterData()} printMode={this.props.printMode} />

            <div class="row justify-content-center row-margin">
                <div class="col-md-3 col-lg-3 mb-3"></div>
                <div class="col-md-6 col-lg-6 mb-6">
                    {this.state.showDownloadForm ?
                        this.state.showConfirmMessage ?
                            <div>
                                Thank you! Your information has been successfully submitted. Check your email inbox or spam-box.
                                    <br /><br />
                                <Button variant="secondary" size="lg" block>
                                    Okay
                                </Button>
                            </div>
                            :
                            <React.Fragment>
                                <form onSubmit={(e) => this.submitForm(e)}>
                                    <div class="row justify-content-center">
                                        <input type="text" className="border-bottom input-big" placeholder="First Name" required />
                                    </div>
                                    <div class="row justify-content-center">
                                        <input type="text" className="border-bottom input-big" placeholder="Last Name" required />
                                    </div>
                                    <div class="row justify-content-center">
                                        <input type="text" className="border-bottom input-big" placeholder="Email" required />
                                    </div>
                                    <div class="row justify-content-center">
                                        <img src={continueIcon} onClick={() => this.printPDF()} className="img-button" />
                                    </div>
                                </form>
                                <br />
                            </React.Fragment>
                        :
                        <div class="row justify-content-center default-mb">
                            <img src={downloadReportIcon} onClick={() => this.setState({ showDownloadForm: true })} className="img-button" />
                        </div>
                    }
                </div>
                <div class="col-md-3 col-lg-3 mb-3">
                    <UpIcon onClick={this.goToTop} className="up-icon img-button" />
                </div>
            </div>
        </React.Fragment>
    }
}