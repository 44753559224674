import React from "react";
import { ReactComponent as InfoIcon } from '../assets/Info_Button.svg';
import Modal from 'react-bootstrap/Modal';

export default class MixDetailLine extends React.Component {
    constructor() {
        super();
        this.state = {
            showModal : false
        }
    }

    eventHandler = event => {
        this.props.handler({target: this.props.params.target, value: event.target.value });
    };

    render() {
        return <div class="row row-bordered justify-content-center select-wrapper">
            <div class="col-md-3 col-lg-3 mb-3">
                {this.props.params.label}

                { this.props.params.tooltip ?
                    <InfoIcon className="info-icon img-button hide-on-print" onClick={() => this.setState({ showModal : true })} />
                    :null
                }
             </div>

            <div class="col-md-3 col-lg-3 mb-3">
                {this.props.params.reference}
            </div>

            <div class="col-md-3 col-lg-3 mb-3">
                {this.props.displayOnly ? 
                    <input type="number" className="input-no-borders" placeholder="Enter" value={this.props.value} disabled />
                    :
                    <input type="number" className="input-no-borders" placeholder="Enter" value={this.props.value} onChange={this.eventHandler} />
                }
            </div>

            <div class="col-md-3 col-lg-3 mb-3">
                <span className="column-description">
                    {this.props.params.unit}
                </span>
            </div>

            <Modal show={this.state.showModal} onHide={()=> this.setState({ showModal : false })}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.params.label}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.props.params.tooltip}</Modal.Body>
            </Modal>
        </div>
    }
}