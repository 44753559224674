import React from "react";
import InputNumber from "./inputNumber.js";
import InputText from "./inputText";
import MixDetail from "./mixDetail";
import SelectItem from './selectItem'

const concreteGradeSelectParams = {
    label: "Select grade of concrete MPa",
    options: [
        { value: 20, label: '20' },
        { value: 25, label: '25' },
        { value: 30, label: '30' },
        { value: 35, label: '35' },
        { value: 40, label: '40' },
        { value: 50, label: '50' },
        { value: 65, label: '65' },
        { value: 80, label: '80+' }
    ]
};

const mixNameParams = { label: "Input name of Mix" };
const concreteVolumeParams = { label: "Select volume of concrete to be used in the project, in m3" };

export default class Mix extends React.Component {
    constructor() {
        super();
    }

    concreteGradeHandler = selectedConcreteGrade => {
        this.props.handler({ target: "selectedConcreteGrade", value: selectedConcreteGrade, index: this.getMixIndex() });

        console.log(`selectedConcreteGrade ${selectedConcreteGrade.value}`);
    };

    mixNameHandler = selectedMixName => {
        this.props.handler({ target: "selectedMixName", value: selectedMixName.value, index: this.getMixIndex() });
        console.log(`selectedMixName ${selectedMixName.value}`);
    };

    concreteVolumeHandler = selectedConcreteVolume => {
        this.props.handler({ target: "selectedConcreteVolume", value: selectedConcreteVolume.value, index: this.getMixIndex() });
        console.log(`selectedConcreteVolume ${selectedConcreteVolume.value}`);
    };

    mixDetailHandler = event => {
        const target = Object.keys(event)[0];
        const value = event[target];

        this.props.handler({target: target, value: value, index : this.getMixIndex() });
    };

    getMixIndex(){
        return this.props.index - 1;
    }

    render() {
        // console.log(this.props);

        return <section class="page-section portfolio no-margin no-padding mix-section">
            <div id={`mix${this.props.index}-header`} class="container no-margin no-padding mix-header">
                <h2 class="page-section-heading text-left text-secondary mb-0">Mix {this.props.index}/{this.props.totalMixes} </h2>
                <br />

                <InputText value={this.props.params.selectedMixName} params={mixNameParams} handler={this.mixNameHandler} />
                <InputNumber value={this.props.params.selectedConcreteVolume} params={concreteVolumeParams} handler={this.concreteVolumeHandler} />
                <SelectItem value={this.props.params.selectedConcreteGrade} params={concreteGradeSelectParams} handler={this.concreteGradeHandler} />

            </div>
            <div class="container no-margin no-padding">
                <MixDetail handler={this.mixDetailHandler} index={this.props.index} value={this.props.params} />
            </div>
        </section>
    }
}