import React from "react";
import MixDetailLine from "./mixDetailLine";
import DetailLineHeader from "./mixDetailLineHeader";
import LifeAssessmentLineHeader from "./lifeAssessmentLineHeader";

import { Bar } from 'react-chartjs-2';

import CalculatorUtils from './calculatorUtils';
import Utils from "./utils";

const UNIT = {
    KG_M3: "Kg / m3 ready mix",
    L_M3: "L / m3 ready mix",
    KWH_M3: "kWh / m3 ready mix",
    KM: "km",
};

const energyAndTransportFields = ["diesel","electricity","coarseAggregateTransport","sandTransport"]
const energyAndTransportColor = "#D2ABDE";

const mixDetailLines = [
    { label: "General purpose cement", target: "purposeCement", reference: "0", unit: UNIT.KG_M3, color: "#BED7E9", tooltip: "General purpose cement for the Reference Mix is as per NZGBC guidelines" },
    { label: "D5 Green", target: "d5Green", reference: "0", unit: UNIT.KG_M3, color: "#00CE7C", tooltip: "With D5 Green at 2-3% of cement you can reduce cement consumption by up to 25%" },
    { label: "Admixtures", target: "admixtures", reference: "0", unit: UNIT.KG_M3, color: "#F05E17" },
    { label: "Silica Fume", target: "silicaFume", reference: "0", unit: UNIT.KG_M3, color: "#9D233F" },
    { label: "Fly Ash", target: "flyAsh", reference: "0", unit: UNIT.KG_M3, color: "#F0CFD7", tooltip: "With fly ash and D5 Green you can reduce cement consumption by over 25%" },
    { label: "Sand", target: "sand", reference: "0", unit: UNIT.KG_M3, color: "#F9C74F" },
    { label: "Coarse Aggregate", target: "coarseAggregate", reference: "0", unit: UNIT.KG_M3, color: "#4B0082" },
    { label: "Water", target: "water", reference: "0", unit: UNIT.L_M3, color: "#407EDA" },
    { label: "Sand Transport", target: "sandTransport", reference: "100", unit: UNIT.KM, color: "#B0C4DE", tooltip: "If you do not have specific information on transport and energy consumption, please use default figures as per the reference mix, representing market averages in NZ and AU." },
    { label: "Coarse Aggregate Transport", target: "coarseAggregateTransport", reference: "100", unit: UNIT.KM, color: "#775BBF", tooltip: "If you do not have specific information on transport and energy consumption, please use default figures as per the reference mix, representing market averages in NZ and AU." },
    { label: "Electricity", target: "electricity", reference: "2", unit: UNIT.KWH_M3, color: "#DB7093", tooltip: "If you do not have specific information on transport and energy consumption, please use default figures as per the reference mix, representing market averages in NZ and AU." },
    { label: "Diesel", target: "diesel", reference: "1", unit: UNIT.L_M3, color: "#8B4513", tooltip: "If you do not have specific information on transport and energy consumption, please use default figures as per the reference mix, representing market averages in NZ and AU." },
];

const lifeCycleAssessmentLines = [
    { label: "Global warming potential", target: "globalWarmingPotential", reference: "320", unit: "kg CO2eq. / m3", decimalPrecision: 0 },
    { label: "Ozone layer depletion", target: "ozoneLayerDepletion", reference: "0", unit: "kg CFC-11 eq. / m3", decimalPrecision: 0 },
    { label: "Acidification", target: "acidification", reference: "0", unit: "kg SO2 eq. / m3", decimalPrecision: 2 },
    { label: "Eutrophication", target: "eutrophication", reference: "0", unit: "kg PO3 4eq. / m3", decimalPrecision: 2 },
    { label: "Photochemical oxidation", target: "photochemicalOxidation", reference: "0", unit: "kg C2H4 4eq. / m3", decimalPrecision: 2 },
    { label: "Abiotic depletion (elements)", target: "abioticElements", reference: "732", unit: "kg Sb eq. / m3", decimalPrecision: 2 },
    { label: "Abiotic depletion (fossil fuels)", target: "abioticFossilFuels", reference: "1030", unit: "MJ / m3", decimalPrecision: 0 },
];
export default class MixDetail extends React.Component {
    constructor() {
        super();
    }

    componentDidMount(){
        try{
            this.calculateDynamicRefences();
            this.calculateMixesValues();
        }catch(e){
            console.log(e);
        }
    }

    genericHandler = event => {
        const stateObject = {};
        stateObject[event.target] = event.value;
        this.setState(stateObject);
        this.props.handler(stateObject);

        //we need this to make sure all the dynamic references are calculated before we calculate the mix values
        this.calculateDynamicRefences();
        this.calculateMixesValues();
    };
    
    calculateMixesValues() {
        const selectedCountry = this.getSelectedCountry();
        console.log('####### Calculation started #######');

        for (const lca of lifeCycleAssessmentLines) {
            let total = 0;

            for (const line of mixDetailLines) {
                const value = this.getValue(line.target);

                let targetValue = CalculatorUtils.calculateLCA(line.target, selectedCountry, lca.target, value);
                targetValue = this.specificFieldCalculation(lca, line, targetValue, selectedCountry, false);

                console.log(`${lca.target} - ${line.target} -> ${targetValue}`);
                total += targetValue;

                line["mix-" + lca.target] = targetValue;
            }

            console.log(`${lca.target}: ${total}`);
            console.log('-----------------------------------');

            const tempObject = {};
            tempObject[lca.target] = total.toFixed(lca.decimalPrecision);

            this.props.handler({ ...tempObject });
        }
    }

    //when we're calculating the LCA reference value, the calculation should be based on the reference values instead of the user's input
    specificFieldCalculation(lca, line, targetValue, selectedCountry, isReferenceLCACalculation) {
        if (isReferenceLCACalculation || this.useReferenceInCalculation(line.target, lca.target)) {
            targetValue = CalculatorUtils.calculateLCA(line.target, selectedCountry, lca.target, line.reference);
        }

        if (line.target === "sandTransport") {
            const sandValue = isReferenceLCACalculation ? this.getReferenceValue("sand") : this.getValue("sand");

            if (sandValue) {
                targetValue *= sandValue;
            }
        }

        if (line.target === "coarseAggregateTransport") {
            const coarseAggregateValue = isReferenceLCACalculation ? this.getReferenceValue("coarseAggregate") : this.getValue("coarseAggregate");

            if (coarseAggregateValue) {
                targetValue *= coarseAggregateValue;
            }
        }

        return targetValue;
    }

    useReferenceInCalculation(field, type){
        if(type === "globalWarmingPotential") return false;
        const fields = ["admixtures", "purposeCement", "d5Green", "silicaFume", "flyAsh", "sand", "sandTransport", "water", "electricity", "diesel", "coarseAggregateTransport"];

        return fields.includes(field);
    }

    getMixDetailLinesLegend(){
        const result = [];

        for(const line of mixDetailLines){
            if(energyAndTransportFields.includes(line.target)){
                continue;
            }
            result.push({label: line.label, color: line.color})
        }

        result.push({label: "Energy & Transport", color: energyAndTransportColor })

        return result;
    }

    calculateDynamicRefences() {
        this.calculatePreRequiredReferences();

        for (const line of mixDetailLines) {
            if (line.target === "sand") {
                const mixValues = this.sumInputValues(["d5Green", "purposeCement", "admixtures", "silicaFume", "flyAsh", "sand", "water", "coarseAggregate"]);
                const referencesValues = this.sumReferencesValues(["d5Green", "purposeCement", "admixtures", "silicaFume", "flyAsh", "water", "coarseAggregate"]);

                const result = Math.round(mixValues - referencesValues);
                line.reference = isNaN(result) ? 0 : result;
            }

            if (line.target === "coarseAggregate") {
                line.reference = this.getValue("coarseAggregate");
            }

            this.saveReferenceInReferenceState(line);
        }
        
        this.calculateLCAReferences();
    }

    persistState = (storageKey, state) => {
        window.localStorage.setItem(storageKey, JSON.stringify(state));
    }

    saveReferenceInReferenceState(line) {
        const mixIndex = this.props.value.index;
        let referenceState = this.getReferenceState();

        if(!referenceState) referenceState = new Object();

        if(!referenceState[mixIndex]){
            referenceState[mixIndex] = new Object()
        }

        referenceState[mixIndex][line.target] = line.reference;
        this.persistState("referenceState", referenceState);
    }

    calculateLCAReferences() {
        const selectedCountry = this.getSelectedCountry();
        console.log("******* calculate LCA for Reference ********");

        //calculate LCA reference
        for (const lca of lifeCycleAssessmentLines) {
            let total = 0;

            for (const line of mixDetailLines) {
                const value = line.reference;

                let targetValue = CalculatorUtils.calculateLCA(line.target, selectedCountry, lca.target, value);
                targetValue = this.specificFieldCalculation(lca, line, targetValue, selectedCountry, true);

                console.log(`${lca.target} - ${line.target} -> ${targetValue}`);
                total += targetValue;

                line["ref-" + lca.target] = targetValue;
            }

            lca.reference = total.toFixed(lca.decimalPrecision);

            this.saveReferenceInReferenceState(lca);

            console.log(`${lca.target}: ${total}`);
            console.log('-----------------------------------');
        }
    }

    calculatePreRequiredReferences() {
        for (const line of mixDetailLines) {
            if (line.target === "purposeCement") {
                const selectedGrade = this.getValue("selectedConcreteGrade");

                if (selectedGrade) {
                    line.reference = CalculatorUtils.getPurposeCementReference(selectedGrade.value);
                }
            }

            if (line.target === "water") {
                const mixValues = this.sumInputValues(["purposeCement", "flyAsh", "silicaFume"]);
                const referencesValues = this.sumReferencesValues(["purposeCement", "flyAsh", "silicaFume"]);

                const result = Math.round(this.getValue("water") / mixValues * referencesValues);
                line.reference = isNaN(result) ? 0 : result;
            }
        }
    }

    sumInputValues(fields){
        let total = 0;
        
        for(const field of fields)
            total += this.getValue(field);

        return total;
    }

    sumReferencesValues(fields){
        let total = 0;

        for(const line of mixDetailLines)
            if(fields.includes(line.target))
                total += eval(line.reference);

        return total;
    }

    getReferenceValue(field){
        for(const line of mixDetailLines)
            if(line.target === field)
                return line.reference;

        return null;
    }

    getValue(field){
        return this.getTargetValue({ target: field });
    }

    getTargetValue(line) {
        if (this.props.value && this.props.value[line.target]) {
            try{
                return eval(this.props.value[line.target]);
            }catch(e){
                return 0;
            }
        }
        return;
    }

    getGlobalState() {
        return Utils.getGlobalState();
    }

    getReferenceState() {
       return Utils.getReferenceState();
    }

    getSelectedCountry(){
        try{
            return this.getGlobalState().selectedCountry.value;
        }catch(e){
            return "NZ";
        }
    }

    static getMixDetailsLines(){
        return mixDetailLines;
    }

    getGraphData() {
        let datasets = [];
        const selectedCountry = this.getSelectedCountry();

        let energyAndTransportReference = 0;
        let energyAndTransportMix = 0;

        for (const line of mixDetailLines) {
            // the calculateCarbon has specific calculations based on specific fields. However, the value matches the global warming potential
            // const mixValue = Math.round(CalculatorUtils.calculateCarbonEmissions(line.target, selectedCountry, this.getTargetValue(line)));
            // const referenceValue = Math.round(CalculatorUtils.calculateCarbonEmissions(line.target, selectedCountry, line.reference));
         
            const mixValue = line["mix-globalWarmingPotential"];
            const referenceValue = line["ref-globalWarmingPotential"];

            if(energyAndTransportFields.includes(line.target)){
                energyAndTransportMix += mixValue
                energyAndTransportReference += referenceValue;

                continue;
            }

            datasets.push({
                label: line.label,
                fill: false,
                lineTension: 0.5,
                backgroundColor: line.color,
                borderColor: 'rgba(0,0,0,1)',
                borderWidth: 0,
                data: [Math.round(referenceValue), Math.round(mixValue)]
            });
        }

        //addinng in the first line of the array
        datasets = [{
            label: "Energy & Transport",
            fill: false,
            lineTension: 0.5,
            backgroundColor: energyAndTransportColor,
            borderColor: 'rgba(0,0,0,1)',
            borderWidth: 0,
            data: [energyAndTransportReference, energyAndTransportMix]
        }].concat(datasets);

        return {
            labels: ['Reference Mix eCO2', 'Actual Mix eCO2'],
            datasets: datasets
        }
    }

    render() {
        this.calculateDynamicRefences();
        const isSmallDevice = Utils.isSmallDevice();

        return <section class="page-section portfolio">
            <div id={`mix${this.props.index}-details`} class="container no-margin no-padding mix-details">
                <DetailLineHeader />
                {mixDetailLines.map((line, i) => <MixDetailLine key={i} handler={this.genericHandler} value={this.getTargetValue(line)} params={line} />)}
            </div>

            <div id={`mix${this.props.index}-graph`} class="container no-margin page-section-top mix-graph">
                <h3 class="text-left text-secondary mb-0">
                    Estimated CO2 per m3 ready mix concrete {this.getSelectedCountry() === "NZ" ? "New Zealand" : "Australia"}
                </h3>
                
                { isSmallDevice ? 
                    <div class="mix-canvas-container">
                        <Bar
                            data={this.getGraphData()}
                            options={{
                                maintainAspectRatio: false,
                                scales: {
                                    xAxes: [{
                                        stacked: true,
                                        gridLines: false,
                                        ticks: {
                                            fontSize: 15,
                                            padding: 30
                                        }
                                    }],
                                    yAxes: [{
                                        stacked: true,
                                        ticks: {
                                            beginAtZero: true,
                                            min: 0, 
                                            fontSize: 15,
                                        }
                                    }]
                                },
                                title: {
                                    display: true,
                                    text: '',
                                    fontSize: 25
                                },
                                legend: {
                                    display: false,
                                }
                            }}
                        />
                    </div>
                : <Bar
                    data={this.getGraphData()}
                    options={{
                        scales: {
                            xAxes: [{
                                stacked: true,
                                gridLines: false,
                                ticks: {
                                    fontSize: 15,
                                    padding: 30
                                }
                            }],
                            yAxes: [{
                                stacked: true,
                                ticks: {
                                    beginAtZero: true,
                                    min: 0, 
                                    fontSize: 15,
                                }
                            }]
                        },
                        title: {
                            display: true,
                            text: '',
                            fontSize: 25
                        },
                        legend: {
                            display: false,
                        }
                    }}
                 /> 
                }

                <div class="row justify-content-center select-wrapper page-section-small">
                    {this.getMixDetailLinesLegend().map((line, i) =>
                        <div className={`graph-legend-${i} col-md-4 col-lg-4 mb-4 row-bordered-full`}>
                            <span class="dot" style={{ backgroundColor: line.color }}></span>
                            <span class="graph-legend-text">{line.label}</span>
                        </div>
                    )}
                </div>
                <br /><br />
            </div>
            <div id={`mix${this.props.index}-lca`} class="container no-margin no-padding mix-lca">
                <div class="container no-margin no-padding">
                    <LifeAssessmentLineHeader />
                    {lifeCycleAssessmentLines.map((line, i) => <MixDetailLine key={i} displayOnly={true} value={this.getTargetValue(line)} params={line} />)}
                </div>
            </div>
        </section>
    }
}