
const DetailLineHeader = props => (
    <div class="row row-bordered justify-content-center select-wrapper">
        <div class="col-md-3 col-lg-3 mb-3">
            <span className="column-header">
                Please populate <br />
            Actual Mix
        </span>
        </div>

        <div class="col-md-3 col-lg-3 mb-3">
            <span className="column-header">
                Reference <br />
            Mix
        </span>
        </div>

        <div class="col-md-3 col-lg-3 mb-3">
            <span className="column-header">
                Actual <br />
            Mix
        </span>
        </div>
        <div class="col-md-3 col-lg-3 mb-3">
            <span className="column-header">
                Unit
        </span>
        </div>
    </div>
)

export default DetailLineHeader;