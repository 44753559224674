
const LifeAssessmentLineHeader = props => (
    <div class="row row-bordered justify-content-center select-wrapper">
        <div class="col-md-12 col-lg-12 mb-12 show-on-print" style={{marginBottom: '10px'}}>
            <h3 class="text-left text-secondary">Life Cycle Assessment (LCA) per m3 of the ready mix concrete in New Zealand</h3>
        </div>
        <div class="col-md-3 col-lg-3 mb-3">
            <span className="column-header">
                        Green Star Impact <br />
                Category
            </span>
                </div>

                <div class="col-md-3 col-lg-3 mb-3">
                    <span className="column-header">
                        Reference <br />
                Mix
            </span>
                </div>

                <div class="col-md-3 col-lg-3 mb-3">
                    <span className="column-header">
                        Actual <br />
                Mix
            </span>
                </div>
                <div class="col-md-3 col-lg-3 mb-3">
                    <span className="column-header">
                        Unit
            </span>
        </div>
    </div>
)

export default LifeAssessmentLineHeader;