import React from "react";
import logo from '../assets/app-logo.webp';
import { ReactComponent as LinkedIn } from '../assets/Social_Button_Linkedin.svg';
import { ReactComponent as Instagram } from '../assets/Social_Button_Instagram.svg';
import { ReactComponent as Logo } from '../assets/NEOCRETE_LOGO_RGB_PRIORITY_1.svg';

const Footer = props => (
    <footer class="footer text-center">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 text-left mb-6 mb-lg-0">
                    {/* <img class="masthead-avatar mb-5" src={logo} alt="" /> */}
                    <Logo className="logo-nav"/>
                </div>
                <div class="col-lg-6 text-left mb-6 mb-lg-0">
                    <ul class="navbar-nav ml-auto footer-nav text-left">
                        <li class="nav-item mx-0 mx-lg-1">
                            <a href="https://www.neocrete.co.nz/ourstory" class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger">Our Story </a>
                            <Instagram onClick={() => window.open('https://www.instagram.com/neocretenz', '_self')} className="instagram-button" />
                            <LinkedIn onClick={() => window.open('https://nz.linkedin.com/company/neocrete', '_self')} className="linkedin-button" />
                        </li>
                        <li class="nav-item mx-0 mx-lg-1"><a href="https://www.neocrete.co.nz/ourproduct" class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger">Our Product</a></li>
                        <li class="nav-item mx-0 mx-lg-1"><a href="https://www.neocrete.co.nz/technical" class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger">Technical</a></li>
                        <li class="nav-item mx-0 mx-lg-1"><a href="https://www.neocrete.co.nz/contact" class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger">Contact</a></li>
                    </ul>
                    <hr class="line"/>
                    <span>
                        Neocrete Ltd ©2020. All Rights Reserved <br/>
                    </span>
                    <span>
                          Website By <strong>Ciochetto Studio</strong>
                    </span>

                </div>
            </div>
        </div>
    </footer>
)

export default Footer;