

export default class Utils {
  static getGlobalState() {
    const savedState = window.localStorage.getItem("calculatorState");
    try {
      if (!savedState) {
        return undefined;
      }
      return JSON.parse(savedState);
    } catch (e) {
      return undefined;
    }
  }

  static isSmallDevice(){
    return window.outerWidth < 576;
  }

  static roundNumber(num){
    return Math.round(num * 100) / 100;
  }

  static getReferenceState() {
    const savedState = window.localStorage.getItem("referenceState");
    try {
      if (!savedState) {
        return undefined;
      }
      return JSON.parse(savedState);
    } catch (e) {
      return undefined;
    }
  }
}
