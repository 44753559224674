import React from "react";
import Mix from './mix';
import Utils from './utils';
import MixDetail from './mixDetail';
import Pagination from 'react-bootstrap/Pagination';
import Button from 'react-bootstrap/Button';
import calculateIcon from '../assets/Calculate_Button.png';
import { ReactComponent as UpIcon } from '../assets/Up_Button.svg';
import Modal from 'react-bootstrap/Modal';
import $ from 'jquery';
export default class Mixes extends React.Component {
    constructor() {
        super();

        const selectedMix = window.location.search;
        let index = 0;
        
        if(selectedMix.indexOf("mix") > 0){
            index = eval(selectedMix.split("mix=")[1]);
        }

        this.state = {
            selectedConcreteGrade: {},
            selectedMixName: "",
            index: index,
            selectedConcreteVolume: "",
            showErrorModal: false,
            missingFields: []
        }
    }

    componentDidMount(){
        $(document).on( 'scroll', function(){
            var y = $(this).scrollTop();
            if (y > $(document).height() / 3 && y < $(document).height() / 1.7) {
                $('.up-icon').fadeIn();
            } else {
                $('.up-icon').fadeOut();
            }
         });
    }

    handleSelect = (selectedIndex, e) => {
        if (selectedIndex < 0 || selectedIndex > this.props.mixes.length - 1) {
            console.log('Invalid mix range');
            return;
        }

        const validated = this.validateMix(selectedIndex);

        if (validated) {
            console.log('change to tab ', selectedIndex);
            this.setState({ index: selectedIndex });
            this.goToTop();
        }
    };

    mixHandler = event => {
        // console.log(`Mix handler:`, event);
        this.props.handler(event);
    };

    validateMix(selectedIndex) {
        const allFields = MixDetail.getMixDetailsLines();
        const globalState = Utils.getGlobalState();

        const mix = globalState.mixes[selectedIndex - 1];
        const missingFields = [];

        if (mix) {
            if(!mix.selectedMixName)
                missingFields.push('Mix name');
            if(!mix.selectedConcreteVolume)
                missingFields.push('Concrete volume');
            if(!mix.selectedConcreteGrade || !mix.selectedConcreteGrade.value)
                missingFields.push('Concrete grade');

            for (const field of allFields) {
                if (!mix[field.target]) {
                    missingFields.push(field.label);
                } else {
                    const mixValue = eval(mix[field.target]);

                    if(mixValue < 0){
                        missingFields.push(`${field.label} - negative number is not allowed`);
                    }
                }
            }
        }

        if (missingFields.length > 0) {
            this.setState({ showErrorModal: true, missingFields: missingFields });

            return false;
        }

        return true;
    }

    goToTop() {
        window.scrollTo(0, document.body.scrollHeight / 6);
    }
    
    goToHeader() {
        window.scrollTo(0, 0);
    }

    calculate = () => {
        console.log('Start calculation');

        const globalState = Utils.getGlobalState();
        let validated = true;

        const missingFields = this.validateGlobalFields();

        if (missingFields.length > 0) {
            this.setState({ showErrorModal: true, missingFields: missingFields });
            return;
        }

        for(const mix of globalState.mixes){
            const mixIndex = mix.index + 1;
            const isValid = this.validateMix(mixIndex);

            if(!isValid){
                validated = false;
                this.setState({ errorMix : `Mix ${mixIndex} - ` });
                break;
            }
        }

        if(validated){
            this.props.modeHandler({ resultMode: true });
            this.goToHeader();
        }
    }

    validateGlobalFields() {
        const globalState = Utils.getGlobalState();
        const missingFields = [];

        if (!globalState || !globalState.selectedCountry || !globalState.selectedCountry.value) {
            missingFields.push('Country');
        }
        if (!globalState || !globalState.selectedConcreteType || !globalState.selectedConcreteType.value) {
            missingFields.push('Type of concrete');
        }
        if (!globalState || !globalState.selectedMixQuantity || !globalState.selectedMixQuantity.value) {
            missingFields.push('Mixes quantity');
        }

        return missingFields;
    }

    render() {
        return (
            <React.Fragment>
                <Pagination className="justify-content-center">
                    {this.props.mixes.map((item, index) => {
                        return <Pagination.Item active={this.state.index >= index} className="white-bg" onClick={() => this.handleSelect(index)}>
                            <span class="dot"></span>
                        </Pagination.Item>
                    })}
                </Pagination>
                {this.props.mixes.map((item, index) => {
                    if (index === this.state.index || this.props.printMode) {
                        return <Mix params={item} handler={this.mixHandler} key={index} index={index + 1} totalMixes={this.props.mixes.length} />
                    }
                })}

                <div class="row justify-content-center">
                    <div class="col-md-3 col-lg-3 mb-3">
                        <span className="column-description">
                            Mix {this.state.index + 1}/{this.props.mixes.length}
                        </span>
                    </div>

                    <div class="col-md-3 col-lg-3 mb-3">
                        {
                            this.state.index !== 0 ? 
                                <Button variant="link" className="btn-black" onClick={() => this.handleSelect(this.state.index - 1)}>
                                    <span class="arrow-left"></span>
                                    Previous Mix
                                </Button>
                            : null
                        }
                    </div>

                    <div class="col-md-3 col-lg-3 mb-3">
                        {
                            this.state.index !== this.props.mixes.length - 1 ?
                                <Button variant="link" className="btn-black" onClick={() => this.handleSelect(this.state.index + 1)}>
                                    Next Mix
                                  <span class="arrow-right"></span>
                                </Button>
                            : null
                        }
                    </div>

                    <div class="col-md-3 col-lg-3 mb-3">
                        <UpIcon onClick={this.goToTop} className="up-icon img-button" />
                    </div>
                </div>
                <div class="row justify-content-center default-mb">
                    <div class="col-md-3 col-lg-3 mb-3"></div>
                    <div class="col-md-6 col-lg-6 mb-6">
                        <img src={calculateIcon} onClick={this.calculate} className="img-button" />
                    </div>
                    <div class="col-md-3 col-lg-3 mb-3"></div>
                </div>

                <Modal show={this.state.showErrorModal} onHide={() => this.setState({ showErrorModal: false })}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.errorMix} Please populate the fields below to continue</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="no-padding">
                        <ul>
                            {this.state.missingFields.map((item, index) => {
                                return <li>{item}</li>
                            })}
                        </ul>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}