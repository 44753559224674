import './App.scss';
import React from "react";

import Navigation from './components/navigation';
import Header from './components/header';
import Footer from './components/footer';
import Helper from './components/helper';
import Calculator from './components/calculator';
import CalculationResult from './components/calculationResult';
import LoadingOverlay from 'react-loading-overlay';
import PrintOnly from './components/printOnly';

export default class App extends React.Component {
  constructor() {
    super();

    this.state = {
      resultMode: false,
      isLoading: false,
      printMode: false
    }
  }

  modeHandler = event => {
    console.log(`Changed Mode:`, event);
    this.setState({ resultMode: event.resultMode });
  };

  printHandler = toggle => {
    console.log(`Print Mode:`, toggle);
    this.setState({ printMode: toggle });
  };

  calculationHandler = event => {
    console.log(`Calculation:`, event);

    if (this.state.greenStarPoints === undefined || this.state.greenStarPoints !== event.greenStarPoints) {
      this.setState({ greenStarPoints: event.greenStarPoints, resultMode: true });
    }
  };

  showSpinner = (event, message) =>{
    this.setState({ isLoading : event });

    if(message){
      this.setState({ spinnerMsg : message });
    }else {
      this.setState({ spinnerMsg : 'Loading' });
    }
  }

  render() {
    return <LoadingOverlay active={this.state.isLoading} spinner text={this.state.spinnerMsg}>
              <div id="app-wrapper" className={this.state.printMode ?  'App print-mode' : 'App'}>
                <Navigation />
                <Header greenStarPoints={this.state.greenStarPoints} resultMode={this.state.resultMode} />

                {
                  this.state.printMode ?
                    <React.Fragment>
                      <Calculator modeHandler={this.modeHandler} printMode={this.state.printMode}/>
                      <CalculationResult calculationHandler={this.calculationHandler} printHandler={this.printHandler} printMode={this.state.printMode} showSpinner={this.showSpinner}/>
                    </React.Fragment>
                    : !this.state.resultMode ?
                      <Calculator modeHandler={this.modeHandler} />
                      : <CalculationResult calculationHandler={this.calculationHandler} printHandler={this.printHandler} showSpinner={this.showSpinner}/>
                }
                <Helper />
                <PrintOnly />
                <Footer />
              </div>
          </LoadingOverlay>
  }
}