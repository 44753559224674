import React from "react";


export default class Helper extends React.Component {
    constructor() {
        super();

        this.state = {
            name: "",
            phoneNumber: "",
            message: "",
            email: "",
        }
    }

    handleSubmit() {
        const mailTo = "info@neocrete.co.nz";
        const subject = `${this.state.name} - Help needed from eCalculator`;
        const breakLine = "%0D%0A";
       
        if(!this.state.name){
            alert("Name is required");
            return;
        }
        
        if(!this.state.companyName){
            alert("Company name is required");
            return;
        }

        if(!this.state.concreteGrade){
            alert("Concrete Grade is required");
            return;
        }

        if(!this.state.currentCementWater){
            alert("Current cement/water quantity is required");
            return;
        }

        const body = `Hi there, ${breakLine}${breakLine}
            User: ${this.state.name} ${breakLine}
            Company name: ${this.state.companyName} ${breakLine}
            Concrete Grade: ${this.state.concreteGrade} ${breakLine}
            Current cement/water quantity: ${this.state.currentCementWater} ${breakLine}
        `;

        window.open(`mailto:${mailTo}?subject=${subject}&body=${body}`)
    }
    
    render() {
        return <section class="page-section bg-primary text-white" id="contact">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-lg-6 mb-6">
                        <h2 class="page-section-heading text-left need-help-text mb-0">Need help?</h2>
                        <br />
                        <h2 class="text-left text-small mb-0">
                        
                        <span class="help-message">
                            Contact Neocrete for mix design guidelines to maximise the carbon reduction in concretes with D5 Green.
                        </span>
                        <hr class="line"/>
                        <span class="help-message">
                            All CO2 and other Life Cycle Assessment calculations in this report are consistent with NZ/AU Green Building Council’s guidelines and created with support from Edge Environment in July 2020.
                        </span>                        
                    </h2>
                    </div>
                    <div class="col-md-6 col-lg-6 mb-6">
                        <form id="contactForm" name="sentMessage" noValidate="novalidate" onSubmit={this.handleSubmit}>
                            <div class="control-group">
                                <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                    <input class="form-control" id="name" type="text" value={this.state.name} onChange={(event) => this.setState({ name: event.target.value })} placeholder="Full name" />
                                </div>
                            </div>
                            <div class="control-group">
                                <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                    <input class="form-control" id="email" type="text" value={this.state.companyName} onChange={(event) => this.setState({ companyName: event.target.value })} placeholder="Company name"  />
                                </div>
                            </div>
                            <div class="control-group">
                                <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                    <input class="form-control" id="email" type="email" value={this.state.email} onChange={(event) => this.setState({ email: event.target.value })} placeholder="Email"  />
                                </div>
                            </div>
                            <div class="control-group">
                                <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                    <input class="form-control" type="text" value={this.state.concreteGrade} onChange={(event) => this.setState({ concreteGrade: event.target.value })} placeholder="Concrete Grade" />
                                </div>
                            </div>
                            <div class="control-group">
                                <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                    <input class="form-control" type="text" value={this.state.currentCementWater} onChange={(event) => this.setState({ currentCementWater: event.target.value })} placeholder="Current cement/water quantity" />
                                </div>
                            </div>
                            {/* <div class="control-group">
                                <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                    <textarea class="form-control" id="message" rows="5" value={this.state.message} onChange={(event) => this.setState({ message: event.target.value })} placeholder="Message"></textarea>
                                </div>
                            </div> */}
                            <br />
                            <div id="success"></div>
                            <div class="form-group"><button class="btn help-button btn-xl" id="sendMessageButton" type="button" onClick={() => this.handleSubmit()}>Submit</button></div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    }
}