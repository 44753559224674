import React from "react";
import SelectItem from './selectItem'
import Mixes from "./mixes";

const countriesSelectParams = {
    label: "Select country",
    options: [
        { value: 'NZ', label: 'NZ' },
        { value: 'AUS', label: 'AUS' }
    ]
};

const concreteSelectParams = {
    label: "Select type of concrete",
    options: [
        { value: 'PRECAST', label: 'Precast' },
        { value: 'IN_SITU', label: 'In Situ' }
    ]
}

const mixesQuantitySelectParams = {
    label: "Select how many mixes",
    options: [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
        { value: 6, label: '6' },
        { value: 7, label: '7' },
        { value: 8, label: '8' },
        { value: 9, label: '9' },
        { value: 10, label: '10' }
    ]
}

export default class Calculator extends React.Component {
    constructor() {
        super();
        const calculatorState = this.getIntialState("calculatorState");

        this.state = calculatorState ? calculatorState : {
            selectedCountry: {},
            selectedConcreteType: {},
            selectedMixQuantity: {},
            mixes: []
        }
    }

    componentDidUpdate(prevProps, prevState) {
        this.saveState();
    }

    saveState() {
        this.persistState("calculatorState", this.state);
    }

    persistState = (storageKey, state) => {
        window.localStorage.setItem(storageKey, JSON.stringify(state));
    }

    getIntialState = (storageKey) => {
        const savedState = window.localStorage.getItem(storageKey);
        try {
            if (!savedState) {
                return undefined;
            }
            return JSON.parse(savedState);
        } catch (e) {
            console.error(`Error loading state :  ` + storageKey);
            return undefined;
        }
    }

    countryHandler = selectedCountry => {
        const countryChanged = this.state.selectedCountry !== selectedCountry;
        this.setState({ selectedCountry });
        console.log(`selectedCountry ${selectedCountry.value}`);

        if(countryChanged){
            console.log('Reloading page')
            window.location.reload();
        }  
    };

    concreteTypeHandler = selectedConcreteType => {
        this.setState({ selectedConcreteType });
        console.log(`selectedConcreteType ${selectedConcreteType.value}`);
    };

    mixQuantityHandler = selectedMixQuantity => {
        this.setState({ selectedMixQuantity });
        console.log(`selectedMixQuantity ${selectedMixQuantity.value}`);

        const newArray = [];
        const mixes = this.state.mixes;

        for (let index = 0; index < selectedMixQuantity.value; index++) {
            if (mixes && mixes[index]) {
                newArray.push(mixes[index]);
                continue;
            }

            const item = new Object();
            item.index = index;

            newArray.push(item);
        }

        this.setState({ mixes: newArray });
    };

    mixesHandler = event => {
        // console.log(`Mixes handler:`, event);
        const mixes = this.state.mixes;

        const mix = mixes[event.index];
        mix[event.target] = event.value;

        this.setState({ mixes: mixes });
    };

    modeHandler = event => {
        this.props.modeHandler(event);
    }

    render() {
        return <React.Fragment>
                    <section class="page-section portfolio" id="request-details">
                        <div class="container">
                            <h2 class="page-section-heading text-left text-secondary mb-0 hide-on-print">Make your calculation</h2>
                            <h2 class="page-section-heading text-left text-secondary mb-0 show-on-print">eCalculator Results</h2>
                            <br />
                            <h3 class="text-left text-secondary mb-0 hide-on-print">Please select from the fields below based on your project requirements.</h3>
                            <br />

                            <SelectItem value={this.state.selectedCountry} params={countriesSelectParams} handler={this.countryHandler} />
                            <SelectItem value={this.state.selectedConcreteType} params={concreteSelectParams} handler={this.concreteTypeHandler} />
                            <SelectItem value={this.state.selectedMixQuantity} params={mixesQuantitySelectParams} handler={this.mixQuantityHandler} />

                        </div>
                    </section>
                    <section class="page-section no-padding portfolio">
                        <div class="container">
                            <Mixes handler={this.mixesHandler} mixes={this.state.mixes} modeHandler={this.modeHandler} printMode={this.props.printMode}/>
                        </div>
                    </section>
            </React.Fragment>
    }
}